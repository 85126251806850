<template>
  <div>
    <v-card-text>
      <v-row class="tw-pt-3">
        <div class="tw-self-center">
          <v-tooltip
            color="info"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                data-intercom-target="dateRangeCalendarPrevious"
                text
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="calcDays(fromDate, -1)"
              >
                <v-icon>
                  {{ icons.mdiArrowLeft }}
                </v-icon>
              </v-btn>
            </template>
            <span>Previous Day</span>
          </v-tooltip>
        </div>
        <v-col
          id="rx-today-home-day-picker"
          cols="12"
          md="3"
        >
          <day-picker-dialog
            :key="refreshDayPicker"
            :date="currentDate"
            @refreshRxTodayList="populatePrescriptionsList"
          ></day-picker-dialog>
        </v-col>
        <div class="tw-self-center">
          <v-tooltip
            color="info"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="currentDate === date"
                data-intercom-target="dateRangeCalendarNext"
                text
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="calcDays(fromDate, 1)"
              >
                <v-icon>
                  {{ icons.mdiArrowRight }}
                </v-icon>
              </v-btn>
            </template>
            <span>Next Day</span>
          </v-tooltip>
        </div>
        <!-- Advanced Search -->
        <v-dialog
          v-model="isDialogVisible"
          persistent
          max-width="800px"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="tw-self-center"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Search by Rx
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Prescription Search</span>
            </v-card-title>
            <v-tabs
              v-model="currentTab"
              centered
            >
              <v-tab>Rx Number</v-tab>
              <v-tab>Patient name</v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item :key="1">
                <div class="tw-pt-8">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            v-model="rxNumber"
                            label="Rx Number"
                            color="primary"
                            dense
                            clearable
                            :autofocus="autofocus"
                            outlined
                            :rules="[integerValidator]"
                            @keydown.enter="populatePrescriptionsListByRx(rxNumber)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    outlined
                    @click="isDialogVisible = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="populatePrescriptionsListByRx(rxNumber)"
                  >
                    Search
                  </v-btn>
                </v-card-actions>
              </v-tab-item>

              <v-tab-item :key="2">
                <div class="tw-pt-8">
                  <compound-patient-search :tab="0"></compound-patient-search>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    outlined
                    @click="isDialogVisible = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>

    <!-- Table https://codepen.io/lzhoucs/pen/aadaJx excellent resource -->
    <v-data-table
      v-model="selected"
      dense
      :loading="loadingRxToday"
      :headers="rxTodayHome"
      item-key="RX NUMBER"
      :options.sync="options"
      :server-items-length="prescriptionsLength"
      :items="prescriptions"
      :search="search"
      :items-per-page="this.itemsPerPage"
      :footer-props="{
        'items-per-page-options': rowsPerPage
      }"
      show-group-by
      class="table-kitchen-sink"
    >
      <!-- :sort-by="sortBy"
						:sort-desc="sortDesc" -->
      <!-- action -->
      <template
        #[`item.documentation`]="{ item }"
        :loading="loadingAmend"
      >
        <v-btn
          plain
          @click="saveRxInfo(item, item['PATIENT ID'])"
        >
          <v-icon
            color="success"
            medium
            class="me-3"
            @click="saveRxInfo(item, item['PATIENT ID'])"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span class="tw-underline">{{ item['RX NUMBER'] }}</span>
        </v-btn>
      </template>
      <template
        #[`item.profile`]="{ item }"
        :loading="loadingAmend"
      >
        <v-icon
          color="primary"
          medium
          class="me-3"
          @click="goToPtProfile(item, item['PATIENT ID'])"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </template>
      <template #item.full_name="{ item }">
        <v-btn
          plain
          @click="goToPtProfile(item, item['PATIENT ID'])"
        >
          <v-icon
            color="primary"
            medium
            class="me-3"
            @click="goToPtProfile(item, item['PATIENT ID'])"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
          <span class="tw-underline">{{ item['PATIENT LAST NAME'] }}, {{ item['PATIENT FIRST NAME'] }}</span>
        </v-btn>
      </template>
      <!-- RxDate -->
      <template
        v-for="header in rxTodayHome.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ref } from '@vue/composition-api'
import { mdiCalendar, mdiMagnify, mdiPencilOutline, mdiAccountOutline, mdiArrowRight, mdiArrowLeft } from '@mdi/js'
import moment from 'moment'
import { integerValidator } from '@core/utils/validation'
import { rxTodayHome } from '@/assets/tableHeaders'
import dayPickerDialog from '@/components/datePickers/dayPickerDialog.vue'
import compoundPatientSearch from '@/views/patient/components/compoundPatientSearch.vue'

export default {
  name: 'RxTodayHome',
  components: {
    dayPickerDialog,
    compoundPatientSearch,
  },
  setup() {
    const search = ref('')

    const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menuref = ref(null)

    const dates = ref([new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)])

    return {
      icons: {
        mdiMagnify,
        mdiCalendar,
        mdiPencilOutline,
        mdiAccountOutline,
        mdiArrowRight,
        mdiArrowLeft,
      },
      dates,
      search,
      date,
      menu,
      modal,
      menuref,
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
  },
  data() {
    return {
      selected: [],
      loadingRxToday: true,
      rxTodayHome,
      searchPrescription: '',
      prescriptionsLength: 0,
      itemsPerPage: 10,
      options: {
        page: 1,
      },
      groupby: 'PATIENT LAST NAME',
      dateFormat: 'YYYY-MM-DD',
      currentDate: moment().format('YYYY-MM-DD'),
      fromDate: moment().format('YYYY-MM-DD'),
      sortBy: 'Rx.RxDate',
      sortDesc: 'DESC',

      prescriptions: [],
      pagination: {
        sortBy: 'date',
      },
      rowsPerPage: [10, 25, 50],
      refreshDayPicker: 0,
      isDialogVisible: false,
      currentTab: 0,
      rxNumber: '',
      autofocus: true,
      integerValidator,
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  created() {
    this.refreshRxList(this.currentDate)
  },
  mounted() {
    this.$root.$on('Refresh_Rx_List', () => {
      this.refreshRxList(this.currentDate)
    })
  },

  // fixme clear the sate when switching phamcies so that the logif for database works
  methods: {
    calcDays(date, days) {
      // format the day to mm/dd/yyyy to get the current day
      const dateFormat = (date || '').toString().split(/\-/g)
      dateFormat.push(dateFormat.shift())
      dateFormat.join('/') || null

      const calcDate = new Date(dateFormat)
      calcDate.setDate(calcDate.getDate() + days)
      this.fromDate = calcDate.toISOString().substring(0, 10)
      this.currentDate = this.fromDate
      this.populatePrescriptionsList(this.fromDate)
      this.refreshDayPicker++
      this.options.page = 1
    },
    getDataFromApi() {
      this.loadingRxToday = true
      this.ApiCall().then(() => {
        if (this.prescriptions.length > 0) {
          this.prescriptionsLength = this.prescriptions[0].totalRx
          this.loadingRxToday = false
        } else {
          this.prescriptionsLength = 0
          this.loadingRxToday = false
        }
      })
    },
    ApiCall() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (this.$store.state.User.pharmacy.serverConfig.databaseType === 'nexxsys') {
          const sort = {
            'RX NUMBER': 'DBA.Prescription.RxNumber',
            full_name: 'DBA.Patient.LastName',
            '': 'DBA.Prescription.FillDate',
          }
          const desc = {
            true: 'DESC',
            false: 'ASC',
            '': 'DESC',
          }
          this.sortBy = sort[sortBy]
          this.sortDesc = desc[sortDesc]
        } else {
          const sort = {
            'RX NUMBER': 'Rx.RXNumber',
            full_name: 'Patient.LastName',
            '': 'Rx.RxDate',
          }
          const desc = {
            true: 'DESC',
            false: 'ASC',
            '': 'DESC',
          }
          this.sortBy = sort[sortBy]
          this.sortDesc = desc[sortDesc]
        }

        this.itemsPerPage = itemsPerPage
        await this.refreshRxList(this.fromDate)
        this.scrollToForm()
        setTimeout(() => {
          resolve({})
        }, 1000)
      })
    },
    scrollToForm() {
      document.getElementById('rx-today-home-day-picker').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    },
    async saveRxInfo(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        selectedMedication: [rx],
      })
      this.$router.push({
        name: 'formhub',
      })
    },
    async goToPtProfile(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        selectedMedication: [rx],
      })
      this.$router.push({
        name: 'patient-profile',
      })
    },
    async refreshRxList(dateSelected) {
      this.populatePrescriptionsList(dateSelected)
    },

    // getPrescriptionsToday(fromDate, itemsPerPage, page)
    async populatePrescriptionsList(dateSelected) {
      this.loadingRxToday = true
      this.fromDate = dateSelected

      // this.options.page = 1
      this.prescriptions =
        (await this.$root.$system.DBAdapterConn.getPrescriptionsToday(
          this.fromDate,
          this.itemsPerPage,
          this.options.page - 1,
          this.sortBy,
          this.sortDesc,
        )) || []

      if (this.prescriptions.length > 0) {
        this.prescriptionsLength = this.prescriptions[0].totalRx
      } else {
        this.prescriptionsLength = 0
      }
      this.loadingRxToday = false
    },
    async populatePrescriptionsListByRx(rxNum) {
      this.loadingRxToday = true
      this.options.page = 1
      if (rxNum === '') {
        this.populatePrescriptionsList(this.currentDate)
      }
      this.prescriptions = (await this.$root.$system.DBAdapterConn.getPrescriptionsTodayByNum(rxNum)) || []
      if (this.prescriptions.length > 0) {
        this.prescriptionsLength = this.prescriptions.length
      } else {
        this.prescriptionsLength = 0
      }
      this.loadingRxToday = false
      this.isDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
