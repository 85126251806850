<template>
  <section id="home">
    <router-view></router-view>
    <div v-if="$route.path === '/home'">
      <div id="home-content">
        <v-row class="tw-pb-4">
          <v-col
            v-for="item in quickLinks"
            :id="item.tourTarget"
            :key="item.image"
            md="4"
            sm="12"
            cols="12"
            class=""
          >
            <div
              class="cursor-pointer tw-h-full tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100 hover:tw-bg-blue-100 hover:tw-shadow-2xl"
              @click="
                $router.push({
                  name: item.page,
                })
              "
            >
              <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
                <div>
                  <div class="tw-avatar">
                    <div class="tw-rounded-full tw-w-14 tw-h-14 tw-shadow">
                      <img :src="item.image" />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 class="tw-card-title">
                    {{ item.title }}
                    <div
                      v-if="item.Status"
                      class="tw-badge tw-badge-info tw-ml-2"
                    >
                      {{ item.Status }}
                    </div>
                  </h2>
                  <p class="tw-text-base-content tw-text-opacity-40">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-card>
        <rx-today-home></rx-today-home>
      </v-card>
    </div>
  </section>
</template>

<script>
import {
  mdiAccountHeart,
  mdiAccountHeartOutline,
  mdiCursorDefaultClick,
  mdiMagnify,
  mdiPill,
  mdiPrescription,
} from '@mdi/js'

import { mapGetters, mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import crypto from 'crypto'
import { HomeINtercomTarget } from '@/assets/formSchema/intercomTargets'
import { hotLinks, quickLinks } from '@/assets/cardItems/index'
import RxTodayHome from '@/components/phoxTables/drug/RxTodayHome.vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile', 'PatientProfile']),
    ...mapState(['User']),
  },
  components: {
    RxTodayHome,
  },
  setup() {
    const filterStore = useFilterStore()

    return { filterStore }
  },
  data() {
    return {
      hotLinks,
      quickLinks,
      userHash: '',
      HomeINtercomTarget,
      user: this.$auth.user,
      patientFullName: '',
      contain: true,
      icons: {
        mdiMagnify,
        mdiPill,
        mdiAccountHeartOutline,
        mdiPrescription,
        mdiAccountHeart,
        mdiCursorDefaultClick,
      },
    }
  },
  mounted() {
    this.userHMAC()
    this.changeNameCase()
    this.bootIntercom()
    this.forceRerender()

    this.$root.$on('Refresh_Status_Bar', () => {
      this.changeNameCase()
      this.preloadForm()
      this.updateIntercom()
    })
    this.$root.$on('Refresh_Table', () => {
      this.changeNameCase()
      this.preloadForm()
      this.updateIntercom()
    })
  },
  methods: {
    forceRerender() {
      this.$store.dispatch('resetPatientData')
      this.$store.dispatch('resetState')
      this.activePharmacyKey += 1
      this.sentryUser()
    },
    userHMAC() {
      const id = this.$auth.user.sub.replace('auth0|', '')
      const key = 'KswW2-SQ_Y45niGx64ON1F6HqKlBMovKVTcSRN4l'
      this.userHash = crypto.createHmac('sha256', key).update(id).digest('hex')
    },
    sentryUser() {
      const id = this.$auth.user.sub.replace('auth0|', '')
      Sentry.setUser({
        // DBType: this.$store.state.User.pharmacy.serverConfig.database,
        email: this.$store.state.User.user.email,
        id,
        username: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        user: this.$auth.user,
        pharmacy: this.$store.state.User.pharmacy.name,
        PharmacyEmail: this.$store.state.User.pharmacy.email,
      })
    },
    bootIntercom() {
      const id = this.$auth.user.sub.replace('auth0|', '')
      this.$intercom.boot({
        user_id: id,
        name: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        email: this.$store.state.User.user.email,
        pharmacy: this.$store.state.User.pharmacy.name,
        'Company Name': this.$store.state.User.pharmacy.name,
        PharmacyEmail: this.$store.state.User.pharmacy.email,
        'Referral url': this.$route.fullPath,
        user_hash: this.userHash,
      })
    },
    updateIntercom() {
      const id = this.$auth.user.sub.replace('auth0|', '')
      this.$intercom.update({
        user_id: id,
        name: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        email: this.$store.state.User.user.email,
        pharmacy: this.$store.state.User.pharmacy.name,
        'Company Name': this.$store.state.User.pharmacy.name,
        DBType: this.$store.state.User.pharmacy.serverConfig.database,
        PharmacyEmail: this.$store.state.User.pharmacy.email,
        'Referral url': this.$route.fullPath,
      })
    },
    select(href) {
      // window.location.href = href
      window.open(href, '_blank')
    },
    capitalizeFirstLetter(str) {
      return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    },
    changeNameCase() {
      if (this.PatientProfile['FIRST NAME'] != null) {
        this.patientFullName = `${this.capitalizeFirstLetter(
          this.PatientProfile['FIRST NAME'],
        )} ${this.capitalizeFirstLetter(this.PatientProfile['LAST NAME'])}`
      }
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/home.scss';

#quick-access-buttons {
  padding-bottom: 1rem;

  .v-card .v-card__title {
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#patientSearchFields div:nth-child(1) div div div.v-input__slot,
#patientSearchFields div:nth-child(2) div div div.v-input__slot {
  padding: 0 12px !important;
  background: white !important;
}

#patientViewFile {
  span.font-weight-black.text-no-wrap {
    font-weight: 900 !important;
    font-size: 24px !important;
  }

  span.pharmacyName {
    font-weight: 900;
    font-size: 16px;
  }
}
</style>
